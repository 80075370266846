import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import ModalImage from "react-modal-image";
import { IMAGE_URL } from "../../../config";

/* USER DEFINED COMPONENTS */
import PageSidebar from "../../../components/sidebar";
import GetInTouchBox from "../../../components/get-in-touch";

const containerAnimation = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { staggerChildren: 0.12, delayChildren: 1 },
  },
};

const animation = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
  hidden: {
    opacity: 0,
    y: 20,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
};

const NursingAssistantTrainingProgram = () => {
  return (
    <AnimatePresence>
      <motion.section
        variants={containerAnimation}
        initial="hidden"
        animate="visible"
        className="program"
      >
        {/* BANNER */}
        <motion.div variants={animation} className="banner">
          <div className="image">
            <img
              src={`${IMAGE_URL}/banners/nursing-assistant-banner-new.webp`}
              alt="Nursing Assistant Program"
            />
          </div>

          <div className="head">
            <div className="container">
              <h1 className="heading">NURSING ASSISTANT PROGRAM</h1>
            </div>
          </div>
        </motion.div>

        {/* DESCRIPTION */}
        <motion.div variants={animation} className="program-details">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-sm-12">
                <p className="big-text">
                  The Nursing Assistant Training Program has been developed to
                  prepare students to pass the California State Nurse Assistant
                  Certification Examination and perform as an entry level NA in
                  various health care settings.
                </p>
              </div>
              <div className="col-xl-8 col-lg-8 col-sm-12">
                <div>
                  <h2 className="heading">CNA Program Overview</h2>
                  <div className="mt-2">
                    <p className="text">
                      With plenty of nursing education programs to pick from, it
                      is critical that candidates select a program that is
                      approved by the California Department of Public Health
                      (CDPH). CNA Colleges, Health care institutions, and CNA
                      training institutions offer such programs. But choosing
                      the right one is the challenge. Have a look at our unique
                      certified nurse assistant training and program.
                    </p>
                    <div className="mt-2">
                      <p className="tagline">Flexible Timings</p>
                      <p className="text">
                        Focused on working individuals, they can opt for morning
                        or evening classes according to their availability.
                      </p>
                    </div>
                    <div className="mt-2">
                      <p className="tagline">Detailed CNA Training Program</p>
                      <p className="text">
                        Elaborate course structure that covers both theory and
                        practical sessions.
                      </p>
                    </div>
                    <div className="mt-2">
                      <p className="tagline">
                        Comprehensive Knowledge Induction
                      </p>
                      <p className="text">
                        We ensure you are trained and graduated as the best,
                        through our in-depth certified nurse programs.
                      </p>
                    </div>
                    <div className="mt-2">
                      <p className="tagline">All Inclusive Syllabus</p>
                      <p className="text">
                        Our CNA certification training not only covers the exam
                        requirements but takes you through necessities like
                        being empathetic and much more.
                      </p>
                    </div>
                    <div className="mt-2">
                      <p className="tagline">No Doubts Are Left Uncleared</p>
                      <p className="text">
                        We don’t just lecture, we mentor you to become an
                        aspiring CNA.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5">
                  <h2 className="heading">
                    What Does A Nursing Assistant Do ?
                  </h2>
                  <div className="mt-2">
                    <p className="text">
                      Nursing Assistants provide hands-on healthcare to
                      patients, helping with bathing, dressing and the basic
                      activities of life.
                    </p>
                    <p className="text">
                      A day in life of a CNA might also include :
                    </p>
                    <ul className="list">
                      <li>
                        Taking patients’ vital signs, which may include
                        temperature and blood pressure
                      </li>
                      <li>Documenting patients’ health issues</li>
                      <li>
                        Feeding patients, measuring and recording their food and
                        liquid intake
                      </li>
                      <li>Cleaning rooms and bed linens</li>
                      <li>Helping with medical procedures</li>
                      <li>Dressing wounds</li>
                    </ul>
                  </div>
                </div>
                <div className="mt-5">
                  <h2 className="heading">
                    Why Choose The Certified Nursing Assistant Program ?
                  </h2>
                  <div className="mt-2">
                    <p className="text">
                      According to the U.S. Bureau of Labor Statistics,
                      California has the most CNA employment. There are now
                      around 100,000 CNAs working. California is also listed in
                      the top 5% of the country for CNA compensation, which
                      averages $17/hour and may start at $40,000 per year. Well
                      over 50% of CNAs work in Nursing Care Centers, and
                      California has 2,500 certified nurse facilities that
                      provide employment.
                    </p>
                  </div>
                </div>
                <div className="mt-5">
                  <h2 className="heading">
                    Where Does A Nursing Assistant Work ?
                  </h2>
                  <div className="mt-2">
                    <p className="text">
                      Nursing Assistants can have their pick of flexible
                      employment opportunities. Typically you can find nursing
                      assistants in skilled nursing facilities, hospitals,
                      hospice facilities. In addition, we’ve seen a rise in
                      hiring CNAs at home health agencies and daycares.
                    </p>
                  </div>
                </div>
                <div className="mt-5">
                  <h2 className="heading">
                    What Are The Admission Requirements ?
                  </h2>
                  <div className="mt-2">
                    <ul className="list mt-0">
                      <li>
                        Must be at least 18 years old. 16 and 17-year-olds who
                        have graduated from high school or passed the California
                        High School Proficiency Exam(CHSPE) must obtain parental
                        consent.
                      </li>
                      <li>Live Scan Fingerprinting</li>
                      <li>
                        Physical exam and TB test done no more than 60 days
                        prior to enrollment.
                      </li>
                      <li>
                        Chest X-Ray needed if positive PPD no more than 1 year.
                      </li>
                      <li>
                        Current CPR by the American Heart Association (Basic
                        Life Support Health Care Provider)
                      </li>
                      <li>
                        Obtain a passing score of (11) on the Wonderlic SLE
                        Test.
                      </li>
                      <li>
                        High School Diploma, high school transcript or
                        certificate of high school equivalency, or GED, and
                        evaluation of courses if a foreign graduate.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="mt-5">
                  <h2 className="heading">
                    How Long Does It Take To Complete The Nursing Assistant
                    Program ?
                  </h2>
                  <div className="mt-2">
                    <p className="text">
                      Clement's Nursing Assistant program is flexible so that
                      anyone can jump-start their career in nursing in as little
                      as 8 weeks
                    </p>
                    <p className="text">Program Schedule :</p>
                    <p
                      className="text"
                      style={{ fontWeight: "700", marginTop: "10px" }}
                    >
                      Full Time : Morning Class Sessions (6 Weeks)
                    </p>
                    <ul className="list">
                      <li>Monday to Friday</li>
                      <li>8:00 am - 4:30 pm</li>
                    </ul>
                    <div className="mt-0">
                      <p className="text" style={{ fontWeight: "700" }}>
                        Part Time : Evening Class Sessions (11 Weeks)
                      </p>
                      <ul className="list">
                        <li>Monday to Thursday</li>
                        <li>4:00 pm - 8:00 pm</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="mt-5">
                  <h2 className="heading">
                    What Types Of Courses Are Taught ?
                  </h2>
                  <div className="mt-2">
                    <p className="text">Courses include:</p>
                    <ul className="list">
                      <li>Patient's Rights</li>
                      <li>
                        Prevention and Management of Catastrophe & Unusual
                        Occurrences
                      </li>
                      <li>Medical-Surgical Asepsis</li>
                      <li>Patient Care Skills</li>
                      <li>Vital Signs</li>
                      <li>Emergency Procedures</li>
                      <li>Rehabilitative Nursing</li>
                      <li>Death and Dying</li>
                      <li>Communication and Interpersonal Skills</li>
                      <li>Body Mechanics</li>
                      <li>Weights and Measures</li>
                      <li>Patient Care Procedures</li>
                      <li>Nutrition</li>
                      <li>Long Term Care Residents</li>
                      <li>Observation and Charting</li>
                    </ul>
                  </div>
                </div>
                {/* <div className="mt-5">
                    <h2 className="heading">How Much The Program Cost ?</h2>
                    <div className="mt-2">
                      <p className="text">
                        The total Program Cost is $1840.00.
                      </p>
                      <p className="text">
                        <Link
                          to="/contact-us"
                          style={{ color: "#7ED644", textDecoration: "none" }}
                        >
                          Contact us
                        </Link>{" "}
                        to learn more about the program.
                      </p>
                    </div>
                  </div>
                  <div className="mt-5">
                    <h2 className="heading">What Does Tuition Cover ?</h2>
                    <div className="mt-2">
                      <table className="table">
                        <thead className="table-head">
                          <th>A. PROGRAM REGISTRATION COST</th>
                          <th>$170.00</th>
                        </thead>
                        <tbody className="table-body">
                          <tr>
                            <td>Registration Fee (Non Refundable)</td>
                            <td>$150.00</td>
                          </tr>
                          <tr>
                            <td>
                              Scholastic Level Exam (SLE Test) (Non-Refundable)
                            </td>
                            <td>$20.00</td>
                          </tr>
                        </tbody>
                      </table>

                      <table className="table">
                        <thead className="table-head">
                          <th>B. TUITION FOR NURSING ASSISTANT</th>
                          <th>$1,425.00</th>
                        </thead>
                        <tbody className="table-body">
                          <tr>
                            <td>Tuition Fees</td>
                            <td>$1200.00</td>
                          </tr>
                          <tr>
                            <td>Textbook & Workbook (set)</td>
                            <td>$95.00</td>
                          </tr>
                          <tr>
                            <td>2 Sets School Uniform</td>
                            <td>$60.00</td>
                          </tr>
                          <tr>
                            <td>Student ID Badge</td>
                            <td>$30.00</td>
                          </tr>
                          <tr>
                            <td>
                              Medical Equipment (Stethoscope & Blood Pressure
                              Cuff)
                            </td>
                            <td>$45.00</td>
                          </tr>
                        </tbody>
                      </table>

                      <table className="table">
                        <thead className="table-head">
                          <th>C. STUDENT OUT OF POCKET COST</th>
                          <th>$240.00</th>
                        </thead>
                        <tbody className="table-body">
                          <tr>
                            <td>
                              Live Scan - Form Available In Student Services
                              Desk
                            </td>
                            <td>$65.00</td>
                          </tr>
                          <tr>
                            <td>
                              Money Order Payable to Regional Testing Center
                            </td>
                            <td>$120.00</td>
                          </tr>
                          <tr>
                            <td>2 Sets School Uniform</td>
                            <td>$60.00</td>
                          </tr>
                          <tr>
                            <td>
                              CPR (BLS HEALTH CARE PROVIDER FROM AMERICAN HEART
                              ASSOCIATION)
                            </td>
                            <td>$55.00</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div> */}
                <div className="mt-5">
                  <h2 className="heading">Where Can I Take My Classes ?</h2>
                  <div className="mt-2">
                    <p className="text">
                      The didactic part is taught at our school campus located
                      at 1225 West 190th Street, Suite 375 Gardena, California
                      90248 Clinical practice will take place at approved
                      clinical sites that are in partnership with Clement Career
                      College
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-sm-12">
                <div className="sideabout-image">
                  <ModalImage
                    small={`${IMAGE_URL}/banners/nursing-assistant-flyer.webp`}
                    large={`${IMAGE_URL}/banners/nursing-assistant-flyer.webp`}
                    alt="Nursing Assistant Program"
                  />
                </div>
                <PageSidebar />
              </div>
            </div>
          </div>
        </motion.div>

        {/* GET IN TOUCH BOX */}
        <motion.div variants={animation} className="get-in-touch">
          <div className="container">
            <GetInTouchBox />
          </div>
        </motion.div>
      </motion.section>
    </AnimatePresence>
  );
};

export default NursingAssistantTrainingProgram;
